<template>
  <div class="view-forgot-password mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <form @submit.prevent="submit">
            <div class="card-body pb-5 px-3 px-lg-5">
              <div class="text-center">
                <img
                  width="181"
                  height="97.27"
                  src="@/assets/images/logo.png"
                />
              </div>
              <h2 class="text-center mb-4 text-uppercase">
                ALTERAR SENHA
              </h2>

              <form-group
                v-model="old_password"
                :is-login="true"
                id="password_old"
                type="password"
                label="Senha atual"
                placeholder="Digite a sua senha atual"
                :errors="errors.old_password"
                :show-error-message="false"
                @change="resetErrors"
              />
              <form-group
                v-model="new_password"
                :is-login="true"
                id="password_new"
                type="password"
                label="Nova senha"
                placeholder="Digite a nova senha"
                :errors="errors.new_password"
                :show-error-message="false"
                @change="resetErrors"
              />
              <form-group
                v-model="new_password_confirm"
                :is-login="true"
                id="new_password_confirm"
                type="password"
                label="Confirme a nova senha"
                placeholder="Confirme a nova senha"
                :errors="errors.new_password_confirm"
                :show-error-message="false"
                @change="resetErrors"
              />

              <div class="row justify-content-center py-4">
                <div class="col-lg-10 d-flex align-items-center">
                  <button
                    type="button"
                    class="btn  btn-outline-secondary btn-block mr-3"
                    @click="$router.go(-1)"
                  >
                    Voltar
                  </button>
                  <button
                    :loading="showLoading"
                    type="submit"
                    :disabled="!isValidFields"
                    class="btn btn-primary btn-block m-0"
                  >
                    <loading :show="showLoading">
                      Alterar senha
                    </loading>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <confirmation
        iconType="check"
        title="Concluído!"
        v-model="success"
        confirmButtonClass="btn btn-primary"
        confirmButtonText="Fechar"
        text="Sua senha foi redefinida com sucesso! Você será redirecionado para fazer login!"
        @confirm="onSuccess"
      />
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { authMixin } from "@/mixins";

export default {
  name: "login",
  mixins: [authMixin],
  data() {
    return {
      old_password: "",
      new_password: "",
      new_password_confirm: "",
      errors: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
        non_field_errors: ""
      },
      showLoading: false,
      forgotDialog: false,
      isValidFields: false,
      success: false
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") ||
        get(this.errors, "password[0]") ||
        get(this.errors, "non_field_errors[0]") ||
        get(this.errors, "token[0]")
      );
    },
    user_token() {
      return this.$route.query?.token;
    }
  },
  watch: {
    old_password() {
      this.validateFields();
    },
    new_password() {
      this.validateFields();
    },
    new_password_confirm() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      const not_empty =
        this.new_password?.length &&
        this.new_password_confirm?.length &&
        this.old_password?.length;
      const isPasswordSame =
        this.new_password == this.new_password_confirm && not_empty;
      this.isValidFields = this.old_password && isPasswordSame;
    },
    resetErrors() {
      this.errors = {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
        non_field_errors: []
      };
    },
    submit() {
      this.resetErrors();

      if (this.new_password !== this.new_password_confirm) {
        this.$message.error(
          "A nova senha esta divergente da confirmação, por favor confira as senhas digitadas"
        );
        return;
      }

      this.showLoading = true;
      this.$store
        .dispatch("user/changePassword", {
          password_old: this.old_password,
          password: this.new_password
        })
        .then(() => {
          this.success = true;
        })
        .catch(this.handleErrors)
        .finally(() => {
          this.showLoading = false;
        });
    },
    onSuccess() {
      this.success = false;
      this.$store.dispatch("user/logout").then();
      // this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-forgot-password {
  width: 100%;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #202124;
  }
  .btn-sm {
    font-size: 14px;
    font-weight: 900;
  }

  .btn {
    padding: 9px 18px;
    border-radius: 4px;
    height: 45px;
    &.btn-outline-secondary {
      color: #cfcfcf;
    }
  }
}
</style>
